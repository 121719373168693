import React from 'react';
import { ReactComponent as New } from 'src/components/MainPage/_shared/icons/statusIcons/new.svg';
import { ReactComponent as Confirm } from 'src/components/MainPage/_shared/icons/statusIcons/confirm.svg';
import { ReactComponent as Cooking } from 'src/components/MainPage/_shared/icons/statusIcons/cooking.svg';
import { ReactComponent as ConfirmTime } from 'src/components/MainPage/_shared/icons/statusIcons/confirmTime.svg';
import { ReactComponent as CookingWait } from 'src/components/MainPage/_shared/icons/statusIcons/cookingWait.svg';
import { ReactComponent as PackingWait } from 'src/components/MainPage/_shared/icons/statusIcons/packingWait.svg';
import { ReactComponent as Delivery } from 'src/components/MainPage/_shared/icons/statusIcons/delivery.svg';
import { ReactComponent as Courier } from 'src/components/MainPage/_shared/icons/statusIcons/courier.svg';
import { ReactComponent as Delivered } from 'src/components/MainPage/_shared/icons/statusIcons/delivered.svg';
import { ReactComponent as Packing } from 'src/components/MainPage/_shared/icons/statusIcons/packing.svg';
import { ReactComponent as Packed } from 'src/components/MainPage/_shared/icons/statusIcons/packed.svg';
import { ReactComponent as Done } from 'src/components/MainPage/_shared/icons/statusIcons/done.svg';
import { ReactComponent as Canceled } from 'src/components/MainPage/_shared/icons/statusIcons/canceled.svg';
import { OrderStatusCode } from 'src/constants/order';

export enum IconSize {
  XXSMALL = 14,
  XSMALL = 24,
  SMALL = 32,
  MEDIUM = 36,
  LARGE = 48,
}

export type StatusFilter = {
  title: string;
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  >;
  fill?: string;
};

export const OrderStatus: Record<number, StatusFilter> = {
  [OrderStatusCode.NEW]: { title: 'Новый', icon: New, fill: '#d0d2d3' },
  [OrderStatusCode.CONFIRM]: {
    title: 'Подтверждён',
    icon: Confirm,
    fill: '#00aeef',
  },
  [OrderStatusCode.COOKING_WAIT]: {
    title: 'В очереди',
    icon: CookingWait,
    fill: '#fbb040',
  },
  [OrderStatusCode.CONFIRM_TIME]: {
    title: 'Ко времени',
    icon: ConfirmTime,
    fill: '#00aeef',
  },
  [OrderStatusCode.COOKING]: {
    title: 'Готовится',
    icon: Cooking,
    fill: '#fbb040',
  },
  [OrderStatusCode.PACKING_WAIT]: {
    title: 'Ждёт сборки',
    icon: PackingWait,
    fill: '#db7dd4',
  },
  [OrderStatusCode.PACKING]: {
    title: 'Сборка',
    icon: Packing,
    fill: '#db7dd4',
  },
  [OrderStatusCode.PACKED]: { title: 'Собран', icon: Packed, fill: '#8dc63f' },
  [OrderStatusCode.DELIVERY]: {
    title: 'В доставке',
    icon: Delivery,
    fill: '#8dc63f',
  },
  [OrderStatusCode.COURIER]: {
    title: 'Передан курьеру',
    icon: Courier,
    fill: '#8dc63f',
  },
  [OrderStatusCode.DELIVERED]: {
    title: 'Доставлен',
    icon: Delivered,
    fill: '#8dc63f',
  },
  [OrderStatusCode.CANCELED]: {
    title: 'Отменён',
    icon: Canceled,
    fill: '#ef5243',
  },
  [OrderStatusCode.DONE]: { title: 'Завершён', icon: Done, fill: '#8dc63f' },
};
