import React, { forwardRef, memo, useEffect, useRef, useState } from 'react';
import { alertError, alertSuccess } from 'src/libs/toastify';
import { APIv4 as API } from 'src/libs/axios';
import ReactHowler from 'react-howler';
import { useInterval } from 'src/hooks';
import { OrderRow } from 'src/pages/orders/shared/services/fetchOrders';

const MemoizedSound = memo(
  forwardRef((_props, ref: any) => {
    return (
      <ReactHowler
        src="/assets/sounds/new-order.mp3"
        playing={false}
        ref={ref}
      />
    );
  })
);

const path =
  'orders?isArchived=false&pageNumber=1&pageSize=25&SortingParams[0].SortOrder=2&SortingParams[0].ColumnName=createdAt';

const Howler = ({ updateOrders }: { updateOrders: () => void }) => {
  // Howler
  const [currentOrders, setCurrentOrders] = useState<OrderRow[]>([]);

  const howlerRef = useRef<any>(null);

  useInterval(() => {
    API.get(path)
      .then(({ data }) => {
        const newOrders = data.data.items
          .filter((el: OrderRow, index: number) => index < 20)
          .filter(
            (order: OrderRow) =>
              !currentOrders
                .map((i) => i.orderNumber)
                .includes(order.orderNumber)
          );
        if (newOrders.length) {
          setCurrentOrders(data.data.items);
          updateOrders();
          alertSuccess('Получен новый заказ', { autoClose: 2000 });
          howlerRef.current?.play();
        }
      })
      .catch((error) => {
        alertError(error, 'Ошибка получения списка заказов');
      });
  }, 30 * 1000);

  useEffect(() => {
    API.get(path)
      .then(({ data }) => {
        setCurrentOrders(data.data.items);
      })
      .catch((error) => {
        alertError(error, 'Ошибка получения списка заказов');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <MemoizedSound ref={howlerRef} />;
};

export default React.memo(Howler);
